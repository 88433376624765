import React from 'react';
import { WebBotton } from './common/WebBotton';
import Container from 'react-bootstrap/Container';
import hero_1_1 from '../assets/images/HomePage/hero_1_1.svg';
import hero_2 from '../assets/images/HomePage/hero_2.png';
import aptoslogo from '../assets/images/HomePage/aptoslogo2.svg';
import Fade from 'react-reveal/Fade';
import ButtonLoad from 'react-bootstrap-button-loader';
import { Link } from "react-router-dom";


const HeroSec = () => {
    return (
        <section className='hero_sec'>
            <Container>
                <div className='row hero_sec_row'>
                    <div className='col-md-6 col-12 '>
                        <Fade bottom duration={1000}>
                            <h1 >
                                {/* Ultramodern Single-Sided DeFi 2.0
                                StableSwap. */}
                                Revolutionary DeFi 2.0 Liquidity Protocol Without Impermanent Loss.
                                </h1>
                                
                        </Fade>
                        <br/>
                        <Fade bottom duration={1500}>
                        <p className='hero_desc' style={{    "margin-top": "2rem!important","margin-bottom": "2rem!important"}}>
                        First-of-its-kind Liability-Centric Automated Market Maker for Stablecoins to eliminate liquidity pair concept by introducing new invariant curve and Asset Liability management for computational efficiency and improving liquidity.
                                            </p>
                            {/* <WebBotton WebBotton="Read Documentation" link="https://docs.mercuryfi.exchange/" target="_blank" rel="noreferrer" /> */}
                        </Fade>
                        <br/>
                        {/* <Fade bottom duration={1500}> */}
{/* <div class="trade_btn_row"> */}
  
                        <div className='hero_btn' >
                                            <a   href="https://docs.mercuryfi.exchange/" target="_blank" rel="noreferrer" >Read Documentation
                                            
                                            </a>
                                            {/* &nbsp; <ButtonLoad>hi</ButtonLoad> */}&nbsp;&nbsp;
                                            <Link to="/dashboard" style={{"margin-top": "0.5rem!important"}}>
                                        {/* <Link to="#0" onClick={remove_menu}> */}

                                            LaunchApp
                                        </Link>
                                            </div>
    {/* </div> */}
                            {/* <WebBotton WebBotton="Read Documentation" link="https://docs.mercuryfi.exchange/" target="_blank" rel="noreferrer" /> */}
                        {/* </Fade> */}
                        {/* <ButtonLoad style={{"margin-top": "0.5rem!important"}} onclick={()=>window.open("https://docs.mercuryfi.exchange/")}>Read Documentation</ButtonLoad>&nbsp;<ButtonLoad style={{"margin-top": "0.5rem!important"}}>hi</ButtonLoad> */}
                    <br/>
                        <Fade bottom duration={2000}>
                        <h6>Powered by <img src={aptoslogo}/> </h6>
                        </Fade>
                        
                       
                    </div>
                    <div className='col-md-6 col-12'>
                        <div className='video_block'>
                            <video loop muted autoPlay playsInline>
                                <source src={require('../assets/images/HomePage/Logo_Animation.mp4')} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            </Container>
            <div className='hero_left'>
                <img src={hero_1_1} alt="hero_1" />
            </div>
            <div className='hero_right'>
                <img src={hero_2} alt="hero_2" />
            </div>
        </section>
    )
}

export default HeroSec;